<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">Exchange Rate</span>
      <v-spacer></v-spacer>
      <v-icon color="blue darken-1" @click="close"> close </v-icon>
    </v-card-title>
    <v-form
      id="add-agency"
      ref="form"
      v-model="validExchangeRate"
      lazy-validation
      @submit.prevent="addExchangeRate"
    >
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="exchangeRate.service"
                :clearable="true"
                v-validate="'required'"
                :items="services"
                item-text="name"
                item-value="code"
                label="Select Service"
                ref="service"
                :rules="serviceRules"
                :error-messages="errors.collect('service')"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="exchangeRate.fromCurrency"
                v-validate="'required'"
                :items="currencies"
                item-text="longDescription"
                item-value="code"
                label="From Currency"
                ref="from_currency"
                :rules="fromCurrencyRules"
                :error-messages="errors.collect('from_currency')"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="exchangeRate.toCurrency"
                v-validate="'required'"
                :items="currencies"
                item-text="longDescription"
                item-value="code"
                label="To Currency"
                ref="to_currency"
                :rules="toCurrencyRules"
                :error-messages="errors.collect('to_currency')"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Rate"
                v-model="exchangeRate.rate"
                v-validate="'required'"
                name="rate"
                type="number"
                ref="rate"
                :rules="rateRules"
                :error-messages="errors.collect('rate')"
                clearable
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-select
                v-model="exchangeRate.status"
                v-validate="'required'"
                :items="status"
                item-text="text"
                item-value="value"
                label="Status"
                ref="status"
                :rules="statusRules"
                :error-messages="errors.collect('status')"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Minimum Amount"
                v-model="exchangeRate.minimumAmount"
                v-validate="'required'"
                name="minimumAmount"
                type="number"
                ref="minimumAmount"
                :rules="rateRules"
                :error-messages="errors.collect('minimumAmount')"
                clearable
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                label="Maximum Amount"
                v-model="exchangeRate.maximumAmount"
                v-validate="'required'"
                name="maximumAmount"
                type="number"
                ref="maximumAmount"
                :rules="rateRules"
                :error-messages="errors.collect('maximumAmount')"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="isValid">
            <v-col cols="12" sm="6">
              <h3>Charges</h3>
              <v-spacer></v-spacer>
            </v-col>
            <v-col cols="12" sm="6" style="text-align: right">
              <v-btn
                color="primary"
                dark
                elevation="24"
                :disabled="!isValid"
                small
                @click="addFee"
              >
                Add New Fee
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="isValid">
            <v-col
              cols="12"
              sm="12"
              v-if="
                exchangeRate && exchangeRate.config && exchangeRate.config.fees
              "
            >
              <v-data-table
                :headers="chargeableServiceHeaders"
                :items="exchangeRate.config.fees"
                :items-per-page="20"
                class="elevation-1"
              >
                <template #[`item.groupCode`]="{ item }">
                  <v-select
                    v-model="item.groupCode"
                    :items="chargeableItems"
                    item-text="name"
                    item-value="code"
                    label=""
                  ></v-select>
                </template>
                <template #[`item.code`]="{ item }">
                  <v-select
                    v-model="item.code"
                    :items="getChargeableItemList(item)"
                    item-text="name"
                    item-value="code"
                    label=""
                  ></v-select>
                </template>
                <template #[`item.title`]="{ item }">
                  <v-text-field
                    label=""
                    clearable
                    v-model="item.title"
                  ></v-text-field>
                </template>
                 <template #[`item.description`]="{ item }">
                  <v-text-field
                    label=""
                    clearable
                    v-model="item.description"
                  ></v-text-field>
                </template>
                <template #[`item.icon`]="{ item }">
                  <v-text-field
                    label=""
                    clearable
                    v-model="item.icon"
                  ></v-text-field>
                </template>
                <template #[`item.minimumAmount`]="{ item }">
                  <v-text-field
                    label=""
                    clearable
                    v-model="item.minimumAmount"
                    type="number"
                    :prefix="
                      item.groupCode === 'PAYMENT-TYPE'
                        ? exchangeRate.fromCurrency
                        : exchangeRate.toCurrencyy
                    "
                  ></v-text-field>
                </template>
                <template #[`item.maximumAmount`]="{ item }">
                  <v-text-field
                    label=""
                    clearable
                    v-model="item.maximumAmount"
                    type="number"
                    :prefix="
                      item.groupCode === 'PAYMENT-TYPE'
                        ? exchangeRate.fromCurrency
                        : exchangeRate.toCurrency
                    "
                  ></v-text-field>
                </template>
                <!-- <template #[`item.feeAppliesMinimumAmount`]="{ item }">
                  <v-text-field
                    label=""
                    clearable
                    v-model="item.feeAppliesMinimumAmount"
                    type="number"
                    :prefix="
                      item.groupCode === 'PAYMENT-TYPE'
                        ? exchangeRate.fromCurrency
                        : exchangeRate.toCurrency
                    "
                  ></v-text-field>
                </template>
                <template #[`item.feeAppliesMaximumAmount`]="{ item }">
                  <v-text-field
                    label=""
                    clearable
                    v-model="item.feeAppliesMaximumAmount"
                    type="number"
                    :prefix="
                      item.groupCode === 'PAYMENT-TYPE'
                        ? exchangeRate.fromCurrency
                        : exchangeRate.toCurrency
                    "
                  ></v-text-field>
                </template> -->
                <template #[`item.feeType`]="{ item }">
                  <v-select
                    v-model="item.feeType"
                    :items="chargeableServiceFeeTypes"
                    item-text="name"
                    item-value="code"
                    label=""
                  ></v-select>
                </template>
                <template #[`item.fee`]="{ item }">
                  <v-text-field
                    v-if="item.feeType === 'VALUE'"
                    label=""
                    clearable
                    v-model="item.fee"
                    type="number"
                    :prefix="
                      item.currency === 'paymentTypeFees'
                        ? exchangeRate.fromCurrency
                        : exchangeRate.toCurrency
                    "
                  ></v-text-field>
                  <v-text-field
                    v-if="item.feeType === 'PERCENTAGE'"
                    label=""
                    clearable
                    v-model="item.fee"
                    type="number"
                    suffix="%"
                  ></v-text-field>
                </template>
                <template #[`item.order`]="{ item }">
                  <v-text-field
                    label=""
                    clearable
                    v-model="item.order"
                    @change="sortFees('order')"
                    type="number"
                  ></v-text-field>
                </template>
                <template #[`item.actions`]="{ item }">
                  <v-btn
                    class="ma-2"
                    outlined
                    color="red"
                    @click="removeItem(item)"
                  >
                    delete
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close" :disabled="loading">
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="addExchangeRate"
          :loading="loading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import ExchangeRateService from "../../../services/exchange-rate.service";

import Services from "./services.config.json";
import PaymentTypes from "./payment-types.config.json";
import PayoutTypes from "./payout-types.config.json";
import ChargeableItems from "./chargeable-items.config.json";

const chargeableServiceFeeTypes = [
  {
    code: "VALUE",
    name: "Value",
  },
  {
    code: "PERCENTAGE",
    name: "Percentage",
  },
];

const chargeableServiceFeeTemplate = {
  currencyCode: null,
  groupCode: "PAYMENT-TYPE",
  code: null,
  feeType: "VALUE",
  minimumAmount: 0.0,
  maximumAmount: 0.0,
  name: null,
  title: null,
  description: null,
  icon: null,
  order: 100,
  createdAt: new Date(),
  updatedAt: new Date(),
  fee: 0.0,
};

const chargeableServices = [
  {
    code: "paymentTypeFees",
    name: "Payment",
    description: null,
    template: chargeableServiceFeeTemplate,
    currency: "fromCurrency",
  },
  {
    code: "payOutTypeFees",
    name: "Pay Out",
    description: null,
    template: chargeableServiceFeeTemplate,
    currency: "toCurrency",
  },
];

const chargeableServiceHeaders = [
  {
    text: "Fee Group",
    align: "start",
    sortable: true,
    value: "groupCode",
    width: "10%",
  },
  { text: "Fee Type", value: "code", width: "10%" },
  { text: "Title", value: "title"},
  { text: "Description", value: "description"},
  { text: "Minimum Amount Per Transaction", value: "minimumAmount" },
  { text: "Maximum Amount Per Transaction", value: "maximumAmount" },
  { text: "Fee Type", value: "feeType" },
  { text: "Fee", value: "fee" },
  { text: "Order", value: "order" },
  { text: "Actions", value: "actions", sortable: false },
];

const exchangeRate = {
  service: "TOPUP",
  fromCurrency: null,
  toCurrency: null,
  status: "ACTIVE",
  rate: 0.0,
  config: null,
};

const configTemplate = {
  fees: [],
};

export default {
  props: {
    item: {
      type: Object,
      default() {
        return { ...JSON.parse(JSON.stringify(exchangeRate)) };
      },
    },
  },
  data: () => ({
    chargeableItems: ChargeableItems,
    chargeableServiceHeaders,
    services: Services,
    chargeableServiceFeeTypes,
    chargeableServices,
    paymentTypes: PaymentTypes,
    payoutTypes: PayoutTypes,
    search: "",
    dialog: false,
    dialogDelete: false,
    loading: false,
    validExchangeRate: true,
    currencies: [
      /* { code: "USD", name: "US Dollars", symbol: "$" },
      { code: "GBP", name: "Great British Pounds", symbol: "£" },
      { code: "EUR", name: "Euros", symbol: "€" },
      { code: "GMD", name: "Gambian Dalasi", symbol: "GMD" }, */
    ],
    headers: [
      {
        text: "From Currency",
        align: "start",
        sortable: true,
        value: "fromCurrency",
      },
      { text: "To Currency", value: "toCurrency" },
      { text: "Rate", value: "rate" },
      { text: "Status", value: "status" },
      { text: "Created", value: "createdAt" },
      { text: "Last Updated At", value: "updatedAt" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    exchangeRate: { ...JSON.parse(JSON.stringify(exchangeRate)) },
    status: [
      { text: "Active", value: "ACTIVE" },
      { text: "Inactive", value: "INACTIVE" },
    ],
    // currencies: ["Euro", "Us Dollar", "Pounds", "Dalasis"],

    codeRules: [
      (v) => !!v || "Code is required",
      (v) => {
        if (!(v && v.length != "")) {
          return "Invalid Code";
        }
        return true;
      },
    ],
    rateRules: [
      (v) => !!v || "Rate is required",
      (v) => {
        if (!(v && v.length != 0)) {
          return "Invalid Code";
        }
        return true;
      },
    ],
    serviceRules: [
      (v) => !!v || "Service is required",
      (v) => {
        if (!(v && v.length != "")) {
          return "Invalid Service";
        }
        return true;
      },
    ],
    fromCurrencyRules: [
      (v) => !!v || "From Currency is required",
      (v) => {
        if (!(v && v.length != "")) {
          return "Invalid Currency";
        }
        return true;
      },
    ],
    toCurrencyRules: [
      (v) => !!v || "To Currency is required",
      (v) => {
        if (!(v && v.length != "")) {
          return "Invalid Currency";
        }
        return true;
      },
    ],
    statusRules: [
      (v) => !!v || "Status is required",
      (v) => {
        if (!(v && v.length != "")) {
          return "Invalid Status";
        }
        return true;
      },
    ],
    minimumAmount: [
      (v) => !!v || "Minimum amount is required",
      (v) => {
        if (!(v && v.length != "")) {
          return "Invalid minimum amount";
        }
        return true;
      },
    ],
    maximumAmount: [
      (v) => !!v || "Maximum amount is required",
      (v) => {
        if (!(v && v.length != "")) {
          return "Invalid maximum amount";
        }
        return true;
      },
    ],
    desserts: [],
    editedIndex: -1,
    exchangeRates: [],
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "New Exchange Rate"
        : "Edit Exchange Rate";
    },
    isValid() {
      return (
        this.exchangeRate &&
        this.exchangeRate.fromCurrency &&
        this.exchangeRate.toCurrency &&
        this.exchangeRate.service
      );
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    getChargeableItemList(item) {
      try {
        return this.chargeableItems.find((row) => row.code === item.groupCode)
          .list;
      } catch (error) {
        //
      }
      return [];
    },
    addFee() {
      try {
        this.exchangeRate.config.fees.push({ ...chargeableServiceFeeTemplate });
      } catch (error) {
        console.log("error", error);
        //
      }
    },
    setExchangeRateConfig(exchangeRate) {
      console.log("setExchangeRateConfig", exchangeRate);
      const item = { ...exchangeRate };
      try {
        if (!item.config) {
          item.config = {};
        }

        if (typeof item.config === "string" || item.config instanceof String) {
          item.config = JSON.parse(item.config);
        }
      } catch (error) {
        item.config = {};
      }
      item.config = {
        ...JSON.parse(JSON.stringify(configTemplate)),
        ...item.config,
      };
      console.log("item.config", item.config);
      console.log("configTemplate", configTemplate);
      return item;
    },
    sortFees(key) {
      try {
        this._sortFees(key);
      } catch(error) {
        //
      }
    },
    _sortFees(key) {
     let data = [ ...this.exchangeRate.config.fees ];
     data = data.sort((a, b) => {
        if (a[key] < b[key]) {
            return -1;
        }
        if (a[key] > b[key]) {
            return 1;
        }
        return 0;
    });
    this.exchangeRate.config.fees = data;
},
    setExchangeRate(exchangeRate) {
      let item = this.setExchangeRateConfig(exchangeRate);
      try {
        //
      } catch (error) {
        console.log("error", error);
      }
      this.exchangeRate = item;
    },
    initiateExchangeRate() {
      this.setExchangeRate(this.item);
      this.dialog = true;
    },
    async getCurrency() {
      const response = await ExchangeRateService.getCurrencies();
      //console.log("get currencies", response.data.currencies)
      this.currencies = response.data.currencies;
      console.log("cur", this.currencies);
    },
    hydratePayload(exchangeRate) {
      const item = { ...exchangeRate };
      try {
        item.config = JSON.stringify(item.config);
      } catch (error) {
        //
      }
      return item;
    },
    removeItem(item) {
      try {
        console.log("item", item);
        const list = this.exchangeRate.config.fees;
        console.log("list", list);
        const index = list.indexOf(item);
        if (index > -1) {
          list.splice(index, 1);
        }
        console.log("list", list);
        // this.exchangeRate.fees = list;
        return list;
      } catch (error) {
        console.log("removeItem", error);
      }
    },
    async addExchangeRate() {
      try {
        this.validExchangeRate = this.$refs.form.validate();
        if (this.validExchangeRate) {
          this.loading = true;
          // console.log("addExchangeRate", this.exchangeRate);
          const payload = this.hydratePayload(this.exchangeRate);
          // console.log(payload);

          await ExchangeRateService.save(payload);
          this.loading = false;
          this.dialog = false;

          this.$store.commit(
            "mShowAlert",
            "Exchange Rate Created Successfully."
          );

          this.loadExchangeRates();
          this.exchangeRate = null;
        } else {
          this.$store.commit("mShowAlert", "Fill missing fields ");
        }
      } catch (error) {
        let message = error;
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          message = error.response.data.message;
        }
        this.$store.commit("mShowAlert", message);
        this.loading = false;
      }
    },

    initialize() {
      this.getCurrency();
      this.initiateExchangeRate();
    },

    editItem(item) {
      this.exchangeRate = { ...item };
      this.dialog = true;
    },

    async deleteItemConfirm() {
      try {
        const item = this.item;
        this.loading = true;
        await ExchangeRateService.delete(item.code);
        this.closeDelete();
        this.loading = false;
        this.loadExchangeRates();
      } catch (error) {
        this.loading = false;
        console.log("delete error", error);
      }
    },

    close() {
      this.$emit("close-dialog");
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
    async loadExchangeRates() {
      this.loading = true;
      try {
        const response = await ExchangeRateService.getList();
        this.exchangeRates = response.data;
      } catch (error) {
        console.log("acl_exchangerates report error", error);
      }
      this.loading = false;
    },
  },
  mounted() {
    // alert("mounted");
  },
};
</script>